import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />
      </Layout>
    )
  }
}

export default BlogIndex

export const indexQuery = graphql`
  query iQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
